import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-error',
  templateUrl: './customer-error.component.html',
  styleUrls: ['./customer-error.component.scss']
})
export class CustomerErrorComponent {

}
