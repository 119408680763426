import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }
  APIUrl = environment.apiUrl;

  customerSearch(imei: any): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/servicecomplaints/servicerequest/imei/'+imei);
  }

  getCustomersWarrantyList(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/servicecomplaints/servicerequest');
  }

  saveCustomersWarranty(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.APIUrl + '/servicecomplaints/servicerequest', data);
  }

  editCustomersWarranty(data: any): Observable<any[]> {
    return this.http.put<any[]>(this.APIUrl + '/servicecomplaints/servicerequest', data);
  }

  getModelTypes() {
    return this.http.get<any[]>(this.APIUrl + '/masters/models');
  }

  getCustomerWarrantyById(serviceComplaintsId: number) {
    return this.http.get<any[]>(this.APIUrl + '/servicecomplaints/servicerequest/' + serviceComplaintsId);
  }
}
