<div class="screen-background">
    <div class="col-md-3 offset-md-6">

        <div class="mb-3 d-flex justify-content-center">
            <img src="assets\images\ispare.png" alt="iSpareLogo" height="40px">
        </div>
        <div class="mb-3">
            <label for="email" class="form-label labels">Email</label>
            <input type="email" class="form-control" id="email" [(ngModel)]="login.email"
                placeholder="Your email address">
        </div>

        <div class="mb-3">
            <label for="password" class="form-label labels">Password</label>
            <input type="password" class="form-control" id="password" [(ngModel)]="login.password"
                placeholder="Your password">
            <!-- <p class="mt-3 d-flex justify-content-end labels">
                <a routerLink="/forgot-password">Forgot Password?</a>
            </p> -->
        </div>

        <button type="button" class="btn button-primary btn-login" (click)="onLogin()">Login</button>
        <div class="alert alert-danger mt-3" role="alert" *ngIf="invalid_credentials">
            <label for="error">Invalid Credentials</label>
        </div>
    </div>
</div>