import { Component, Input, OnInit } from '@angular/core';
import { CustomerDetails } from 'src/app/Models/customer.model';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit{
  @Input() childData: CustomerDetails = new CustomerDetails('');
  warranty: boolean = false;
  
  ngOnInit(): void {
    if(this.childData.warranty=='Under Warranty'){
        this.warranty = true;
    }
    else if(this.childData.warranty=='Warranty Expired'){
      this.warranty = false;
    }
  }

  ngOnChanges(): void {
    if(this.childData.warranty=='Under Warranty'){
      this.warranty = true;
  }
  else if(this.childData.warranty=='Warranty Expired'){
    this.warranty = false;
  }
  }
}
