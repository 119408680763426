export class CustomerDetails {
    createdTs: string;
    createdUserId: string | null;
    customerId: string | null;
    customerName: string;
    dateOfService: string;
    emailId: string;
    imei: string;
    modelName: string;
    phoneNo: string;
    plan: string;
    serviceComments: string;
    serviceComplaintsId: number;
    serviceDate: string;
    serviceEngineer: string;
    uniqueId: string;
    updatedTs: string | null;
    updatedUserId: string | null;
    warranty: string;
    warrantyDate: string;
    warrantyExpDate: string;
  
    constructor(data: any) {
      this.createdTs = data.createdTs;
      this.createdUserId = data.createdUserId;
      this.customerId = data.customerId;
      this.customerName = data.customerName;
      this.dateOfService = data.dateOfService;
      this.emailId = data.emailId;
      this.imei = data.imei;
      this.modelName = data.modelName;
      this.phoneNo = data.phoneNo;
      this.plan = data.plan;
      this.serviceComments = data.serviceComments;
      this.serviceComplaintsId = data.serviceComplaintsId;
      this.serviceDate = data.serviceDate;
      this.serviceEngineer = data.serviceEngineer;
      this.uniqueId = data.uniqueId;
      this.updatedTs = data.updatedTs;
      this.updatedUserId = data.updatedUserId;
      this.warranty = data.warranty;
      this.warrantyDate = data.warrantyDate;
      this.warrantyExpDate = data.warrantyExpDate;
    }
  }