<div>
    <div class="d-flex justify-content-between" style="background-color: black;color: white; border: 1px solid #FFF;">
        <div class="p-3">
            <h5>{{popupTitle}}</h5>
        </div>
        <div class="p-3">
            <img src="assets/images/close.JPG" alt="iSpareLogo" style="cursor: pointer;" height="15px"
                (click)="close()">
        </div>
    </div>

    <div class="m-3">
        <form #customerWarrantyForm="ngForm" (ngSubmit)="submitForm(customerWarrantyForm)">

            <div class="form-group mt-2">
                <label for="imei" class="required-label">IMEI Number</label>
                <input type="text" class="form-control" id="imei" name="imei" placeholder="Enter IMEI Number Here..."
                    [(ngModel)]="customerWarranty.imei" required>
            </div>

            <div class="form-group mt-2">
                <label for="modelID" class="required-label">Model</label>
                <select class="form-select" id="modelID" name="modelID" [(ngModel)]="customerWarranty.modelID" required>
                    <option [value]=0 disabled>Select Model/Device</option>
                    <option *ngFor="let value of modelTypeList" [value]="value.modelID">{{value.modelName}}</option>
                </select>
                <!--  <select class="form-select" id="model" name="model" [(ngModel)]="modelType" required>
                    <option value=0 disabled selected>Select Model/Device</option>
                    <option *ngFor="let value of modelTypeList" [ngValue]="value">{{value.modelName}}</option>
                </select> -->
            </div>

            <div class="form-group mt-2">
                <label for="name" class="required-label">Customer Name</label>
                <input type="text" class="form-control" id="name" name="name" placeholder="Enter Customer Name Here..."
                    [(ngModel)]="customerWarranty.customerName" required>
            </div>

            <div class="row mt-2">
                <div class="col-md-6">
                    <label for="email">Email ID</label>
                    <input type="text" class="form-control" id="email" name="email" placeholder="Enter Email ID Here"
                        [(ngModel)]="customerWarranty.emailId">
                </div>

                <div class="col-md-6">
                    <label for="phone" class="required-label">Phone Number</label>
                    <input type="text" class="form-control" id="phone" name="phone" required
                        placeholder="Enter Customer Name Here" [(ngModel)]="customerWarranty.phoneNo">
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-6">
                    <label class="required-label">Date of Service</label>
                    <mat-form-field style="width: 100%;">
                        <input matInput [matDatepicker]="servicepicker" name="servicepicker" required
                            [(ngModel)]="dateOfService" placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix [for]="servicepicker"></mat-datepicker-toggle>
                        <mat-datepicker #servicepicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <label class="required-label">Warranty Expiry Date</label>
                    <mat-form-field style="width: 100%;">
                        <input matInput [matDatepicker]="expirypicker" name="expirypicker" required
                            [(ngModel)]="warrantyExpDate" placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix [for]="expirypicker"></mat-datepicker-toggle>
                        <mat-datepicker #expirypicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group mt-2">
                <label for="location" class="required-label">Location</label>
                <select class="form-select" id="location" name="location" required
                    [(ngModel)]="customerWarranty.location">
                    <option value="" disabled selected>Select Location Here</option>
                    <option *ngFor="let value of locationList" [value]="value.location">{{value.location}}</option>
                </select>
            </div>

            <div class="form-group mt-2">
                <label for="plan">Plan</label>
                <select class="form-select" id="plan" name="plan" [(ngModel)]="customerWarranty.plan">
                    <option value="" disabled selected>Select Plan Here</option>
                    <option *ngFor="let value of plans" [value]="value.type">{{value.type}}</option>
                </select>
            </div>

            <div class="form-group mt-2">
                <label for="comment">Comments</label>
                <textarea class="form-control" id="comment" name="comment" rows="4"
                    [(ngModel)]="customerWarranty.serviceComments">
                </textarea>
            </div>

            <hr>

            <div class="d-flex justify-content-end">
                <button type="submit" class="btn button-primary me-3">Submit</button>
                <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
            </div>
        </form>
    </div>
</div>