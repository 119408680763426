<div class="container mt-3">
    <div class="row">
      <div class="col-12 text-center">
        <span>
          <img src="assets/header-logo.png" alt="Logo Image" id="logo">
        </span>
      </div>
    </div>


    <div class="row">
        <div class="wrapper search-container mt-4 offset-md-2 col-md-8">
            <div class="input-group input-group-lg">
                <div class="static-text-container">
                    <span class="static-text">IMEI NUMBER  </span> 
                    <span class="vl"></span> 
                </div>
          
                <div class="form-outline col-md-12" data-mdb-input-init>
                  <input id="search-input" type="search" id="form1" class="form-control search-input"
                  placeholder="Check the status of your Warranty. Enter your IMEI number to proceed" [(ngModel)]="searchInput" (keyup.enter)="searchcustomer()"/>
                </div>
                
                <span>
                    <img src="assets/Search.png" alt="Search Image" id="logo" class="searchimage" (click)="searchcustomer()">
                </span> 
            </div>
        </div>
    </div>

    <div class="row" *ngIf="displayDetails">
      <app-customer-details [childData]="customerDetails"></app-customer-details>
    </div>
    
    <div class="row" *ngIf="errorDetails">
      <app-customer-error></app-customer-error>
    </div>

   
</div>
<footer>
  <app-footer></app-footer>
</footer>
  