<div class="row mt-5">
    <div class="col-12 text-center content" >
        <div class="card ispare-card mx-auto offset-md-3 col-md-6 mt-5 mb-5">  
            <div class="card-body ispare-card">
                <img src="assets/illustratiion.png" alt="Logo Image" id="logo" class="pt-2">
                <div class="offset-md-3 col-md-6 mt-5 mb-3">  
                        <h6>Oops! This looks empty!</h6>

                            <p>  we couldn't find what you are looking for, please contact
                                <span class="customercare">customer care</span></p>
                </div>
            </div>
        </div>
    </div>
</div>