export class CustomerWarranty {
    imei = '';
    customerId = 0;
    customerName = '';
    emailId = '';
    phoneNo = '';
    dateOfService = '';
    warrantyExpDate = '';
    serviceComments = '';
    plan = '';
    location = '';
    modelID = 0;
    modelName = '';
    serviceComplaintsId = 0;


    // serviceComplaintsId: 0,    
    // warranty: string,
    // serviceDate: string,
    // warrantyDate: string,    
    // plan: string,
    // uniqueId: string
}