import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  login = {
    email: '',
    password: null
  };
  invalid_credentials = false;

  constructor(private router: Router,) { }

  ngOnInit(): void { }

  onLogin() {
    if (this.login.email == 'info@ispareonline.com' && this.login.password == 'ispare@2024') {
      this.router.navigate(['/customers/warranty-portal']);
      console.log("success login")
    } else {
      this.invalid_credentials = true;
    }
  }
}
