import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/services/shared.service';
import { CustomerWarranty } from './customer-warrenty-model';

@Component({
  selector: 'app-customer-warranty-form',
  templateUrl: './customer-warranty-form.component.html',
  styleUrls: ['./customer-warranty-form.component.scss']
})
export class CustomerWarrantyFormComponent {

  customerWarranty = new CustomerWarranty();
  modelTypeList: any[] = [];
  engineerList = [{ name: 'Engineer 1' }, { name: 'Engineer 2' }, { name: 'Engineer 3' }, { name: 'Engineer 4' }, { name: 'Engineer 5' }];
  plans = [{ type: 'iSpare Assure Elite' }, { type: 'iSpare Basic' }, { type: 'iSpare Pro' }];
  locationList = [{ location: 'Edapally' }, { location: 'Menaka' }, { location: 'Vytila' }]
  dateOfService: any;
  warrantyExpDate: any;
  popupTitle = '';

  constructor(private service: SharedService
    , public datePipe: DatePipe
    , public dialogRef: MatDialogRef<CustomerWarrantyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public serviceComplaintsId: any) { }

  ngOnInit(): void {
    this.service.getModelTypes().subscribe((model: any) => {
      this.modelTypeList = model;
    });
    if (this.serviceComplaintsId) {
      this.popupTitle = 'Edit';
      this.service.getCustomerWarrantyById(this.serviceComplaintsId).subscribe((res: any) => {
        this.customerWarranty = res;
        this.dateOfService = this.customerWarranty.dateOfService;
        this.warrantyExpDate = this.customerWarranty.warrantyExpDate;
      });     
    } else {
      this.popupTitle = 'Add New';
    }
  }

  submitForm(customerWarrantyForm: NgForm) {
    if (customerWarrantyForm.valid && this.customerWarranty.modelID) {
      this.customerWarranty.dateOfService = this.datePipe.transform(this.dateOfService, 'yyyy-MM-dd') || '';
      this.customerWarranty.warrantyExpDate = this.datePipe.transform(this.warrantyExpDate, 'yyyy-MM-dd') || '';
      if (this.serviceComplaintsId) {
        this.service.editCustomersWarranty(this.customerWarranty).subscribe((data: any) => {
          if (data && data.title == 'Success') {
            this.close();
          }
        });
      } else {
        this.service.saveCustomersWarranty(this.customerWarranty).subscribe((data: any) => {
          if (data && data.title == 'Success') {
            this.close();
          }
        });
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
