
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDetails } from 'src/app/Models/customer.model';
import { SharedService } from 'src/services/shared.service';


@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent {
  customerDetails: any=[];
  constructor(private router: Router,private service: SharedService){}

  searchInput: string = '';
  displayDetails:boolean = false;
  errorDetails:boolean = false;

  ngOnInit(): void {
    this.displayDetails=false;
    this.errorDetails=false;
  }

  searchcustomer() {

      this.service.customerSearch(this.searchInput).subscribe(data => {
        if(data){
          this.customerDetails = data;
          const serviceComplaintDetails = new CustomerDetails(this.customerDetails );
          if(serviceComplaintDetails.imei === null){
            this.errorDetails=true;
            this.displayDetails=false;
          }
          else {
            this.displayDetails=true;
            this.errorDetails=false;
          }
        }
        

      },
      error => {
        console.error('An error occurred:', error);
      }
      );
    
    // Additional logic for searching customers can be added here
  }
}
