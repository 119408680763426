<div class="m-4">
  <div>
    <h4>Customer Warranty Portal</h4>
  </div>
  <hr>
  <div>

    <div class="d-flex justify-content-between">
      <div class="w-25">
        <input type="text" class="form-control" id="search" [(ngModel)]="search" [readonly]="isReadOnly" (input)="applyFilter()"
          placeholder="Search using Phone number/Name">
      </div>
      <button type="button" class="btn button-primary" (click)="openWarrentyPopup()">+ ADD NEW</button>
    </div>
  </div>

  <div class="mat-elevation-z8 mt-3">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="serviceComplaintsId">
        <th mat-header-cell *matHeaderCellDef>Sl#</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ getSerialNumber(i) }}</td>
      </ng-container>

      <ng-container matColumnDef="imei">
        <th mat-header-cell *matHeaderCellDef>IMEI Number</th>
        <td mat-cell *matCellDef="let row">{{ row.imei }}</td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
      </ng-container>

      <ng-container matColumnDef="warrantyExpDate">
        <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
        <td mat-cell *matCellDef="let row">{{ row.warrantyExpDate | date: 'dd-MMM-yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="serviceDate">
        <th mat-header-cell *matHeaderCellDef>Last Service Date</th>
        <td mat-cell *matCellDef="let row">{{ row.serviceDate | date: 'dd-MMM-yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="warranty">
        <th mat-header-cell *matHeaderCellDef>Warranty Status</th>
        <td mat-cell *matCellDef="let row">{{ row.warranty }}</td>
      </ng-container>

      <ng-container matColumnDef="modelName">
        <th mat-header-cell *matHeaderCellDef>Model/Type</th>
        <td mat-cell *matCellDef="let row">{{ row.modelName }}</td>
      </ng-container>

      <ng-container matColumnDef="serviceComments">
        <th mat-header-cell *matHeaderCellDef>Comment</th>
        <td mat-cell *matCellDef="let row">
          <label class="ellipsis-column">{{ row.serviceComments }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"><img src="assets/images/edit-icon.png" alt="iSpareLogo" style="cursor: pointer;" height="15px" (click)="onEdit(row)"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 30, 1000]" showFirstLastButtons></mat-paginator>

  </div>

</div>