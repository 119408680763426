<nav class="navbar navbar-dark bg-dark">
    <div class="ms-2 d-flex justify-content-center">
        <img src="assets/images/ispare.png" alt="iSpareLogo" height="40px">
    </div>
    <!-- <div class="user-info me-5">
        <p style="margin-bottom: 0px;">John Doe</p>
        <p style="line-height: 2;">logout</p>
    </div> -->
    <div class="user-info  me-5">
        <span class="username">John Doe</span>
        <a href="/employee">Logout</a>
      </div>
  </nav>