<div class="row mt-5">
    <div class="col-12 content" >
        <div class="card ispare-card mx-auto offset-md-3 col-md-6 mt-5 mb-5">  
            <div class="card-body ispare-card">
                <h3 class="card-title">{{ childData.customerName }}</h3>
                <img *ngIf="warranty" src="assets/warranty_covered.png" alt="warranty_covered" id="covered">
                <img *ngIf="!warranty" src="assets/warranty_expired.png" alt="warranty_expired"  id="expired">
                <div class="col-md-12 pt-3" style="display:flex">
                    <div class="col-md-6" style="display:grid">
                        <label for="imei">IMEI #:</label>
                        <span>{{childData.imei}}</span>
                    </div>

                    <div class="col-md-6" style="display:grid">
                        <label for="model">Model:</label>
                        <span>{{childData.modelName}}</span>
                    </div>
                </div>

                <div class="col-md-12 pt-3" style="display:flex">
                    <div class="col-md-6" style="display:grid">
                        <label for="lastservice">Last Service date:</label>
                        <span>{{childData.serviceDate}}</span>
                    </div>

                    <div class="col-md-6" style="display:grid">
                        <label for="expirydate">Expiry date:</label>
                        <span>{{childData.warrantyDate}}</span>
                    </div>
                </div>

                <!-- <div class="col-md-12 pt-3 pb-3" style="display:grid;text-align:left">
                    <label for="comments">Comments:</label>
                    <span class="commentstyle">
                        {{childData.serviceComments}}
                    </span>
                </div> -->

                <div class="col-md-12 pt-3 pb-3" style="display:grid;text-align:left">
                    <label for="comments">Plan:</label>
                    <span class="commentstyle">
                        {{childData.plan}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>