import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'src/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerWarrantyFormComponent } from '../customer-warranty-form/customer-warranty-form.component';

@Component({
  selector: 'app-customer-warranty-portal',
  templateUrl: './customer-warranty-portal.component.html',
  styleUrls: ['./customer-warranty-portal.component.scss']
})
export class CustomerWarrantyPortalComponent {
  search = '';
  isReadOnly = true;
  dataLength = 0;
  displayedColumns: string[] = ['serviceComplaintsId', 'imei', 'customerName', 'warrantyExpDate', 'serviceDate', 'warranty', 'modelName', 'serviceComments', 'action'];
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  dataSource = new MatTableDataSource<any>([]);
  warrantList: any[] = [];

  constructor(private service: SharedService
    , public dialog: MatDialog) {
    setTimeout(() => {
      this.isReadOnly = false;
    }, 500);
  }

  ngOnInit(): void {
    this.getCustomersWarrantyList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getCustomersWarrantyList() {
    this.service.getCustomersWarrantyList().subscribe((data: any) => {
      this.warrantList = data;
      this.dataSource.data = this.warrantList;
      this.dataSource.paginator = this.paginator;
    });
  }
  // Function to get the serial number for each row
  getSerialNumber(index: number): number {
    return this.paginator.pageSize * this.paginator.pageIndex + index + 1;
  }

  // Apply filter function for the search input
  applyFilter() {
    if (this.search !== undefined || this.search !== '') {
      this.dataSource.filter = this.search.trim().toLowerCase();
      this.dataSource.paginator?.firstPage();
    }
  }

  openWarrentyPopup(): void {
    const dialogRef = this.dialog.open(CustomerWarrantyFormComponent, {
      width: '600px',
      height: '90vh',
      maxHeight: '860px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCustomersWarrantyList();
    });
  }

  onEdit(data: any): void {
    const dialogRef = this.dialog.open(CustomerWarrantyFormComponent, {
      width: '600px',
      height: '90vh',
      maxHeight: '860px',
      disableClose: true,
      data: data.serviceComplaintsId
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCustomersWarrantyList();
    });
  }

}
