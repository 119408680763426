import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './employee/login/login.component';
import { SearchCustomerComponent } from './customer/search-customer/search-customer.component';
import { HomeComponent } from './employee/home/home.component';
import { CustomerWarrantyPortalComponent } from './employee/customer-warranty-portal/customer-warranty-portal.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'customer',
    pathMatch: 'full'
  },
  {
    path: 'customer',
    component: SearchCustomerComponent,
    data: { returnUrl: window.location.pathname }
  },
  {
    path: 'employee',
    component: LoginComponent,
  },
  {
    path: 'customers',
    component: HomeComponent,
    children: [{
      path: '',
      redirectTo: 'warranty-portal',
      pathMatch: 'full'
    },
    {
      path: 'warranty-portal',
      component: CustomerWarrantyPortalComponent
    },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
